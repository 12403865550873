export const settingImg = 'http://admin.komdaam.com/assets/images/setting/'
export const categoryImg = 'http://admin.komdaam.com/assets/images/category/'
export const sliderImg = 'http://admin.komdaam.com/assets/images/slider/'
export const productImg = 'http://admin.komdaam.com/assets/images/product/'
export const bannerImg = 'http://admin.komdaam.com/assets/images/banner/'
export const testimonialImg = 'http://admin.komdaam.com/assets/images/testimonials/'
export const profileImg = 'http://admin.komdaam.com/assets/images/img/'


export const campaignImg = 'http://admin.komdaam.com/assets/images/campaign/'
export const blogImg = 'http://admin.komdaam.com/assets/images/blog/'
export const offerImg = 'http://admin.komdaam.com/assets/images/offer/'